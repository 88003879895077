import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import datePickerLocale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { beautifyLoadTracking } from './help.js';
export default {
  data: function data() {
    return {
      datePickerLocale: datePickerLocale,
      dateFormat: "YYYY-MM-DD",
      search: {
        equipId: [],
        stationId: localStorage.getItem("stationId"),
        startTime: moment(),
        endTime: moment()
      },
      selectedItems: [],
      data: [],
      equipmentList: [],
      load_tracking_chart: null,
      load_tracking_option: null,
      timePrice: null,
      colors: ["rgba(210, 98, 98, 0.15)", "rgba(244, 91, 99, 0.15)", "rgba(248, 181, 81, 0.2)", "rgba(23,125,220,0.25)"]
    };
  },
  mounted: function mounted() {
    var token = localStorage.getItem("currentUser_token");

    if (!token && typeof token != "undefined" && token != 0) {
      this.$router.replace({
        path: "/"
      });
      return;
    }

    var id = localStorage.getItem("stationId");

    if (!id && typeof id != "undefined" && id != 0) {
      this.$router.replace({
        path: "/distribution"
      });
    }

    var this_ = this;
    this.getElectricityPrice();
    this_.getEquipmentList();
    this_.init("load-tracking-chart");
    this_.reflush();
  },
  methods: {
    handleChange: function handleChange(selectedItems) {
      this.selectedItems = selectedItems;
    },
    onChange: function onChange() {
      if (typeof this.search.startTime === "string") {
        this.search.startTime = this.search.startTime.split(" ")[0];
        this.search.startTime = moment(this.search.startTime);
      }

      if (typeof this.search.endTime === "string") {
        this.search.endTime = this.search.endTime.split(" ")[0];
        this.search.endTime = moment(this.search.endTime);
      }
    },
    getEquipmentList: function getEquipmentList() {
      var this_ = this;
      var data = {
        stationId: localStorage.getItem("stationId")
      };
      axios({
        method: "post",
        url: "/emind/config/equipment/search_condition",
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error("登录超时，请重新登录", 2, function () {});
          return;
        }

        if (d.data.code === "0") {
          this_.equipmentList = d.data.data;
          this_.equipmentList.map(function (item) {
            item.name = item.numbering.split("-").length == 2 ? item.name : item.parentEquipmentName + "-" + item.num + "#" + item.typeName;
          });
        } else {
          this_.$message.error("请求出错", 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != "") {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getElectricityPrice: function getElectricityPrice() {
      var this_ = this;
      this_.data.splice(0, this_.data.length);
      var date = new Date();
      axios.get("/emind/config/time/price/list/" + localStorage.getItem("stationId")).then(function (d) {
        //
        if (!d) {
          this_.$message.error("登录超时，请重新登录", 2, function () {});
          return;
        }

        if (d.data.code === "0") {
          if (d.data.data.length > 0) {
            this_.timePrice = d.data.data[0];
          }
        } else {
          this_.$message.error(d.data.message, 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != "") {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    reflush: function reflush() {
      var this_ = this;
      var data = {
        stationId: this.search.stationId
      };

      if (this.search.startTime != null && this.search.endTime != null && this.search.startTime.toDate().getTime() > this.search.endTime.toDate().getTime()) {
        this.$message.error("结束时间早于起始时间，请重新设置查询时间！");
        return;
      } else {
        var dataTime = "";

        if (this_.search.startTime != null) {
          dataTime = this_.search.startTime.format("YYYY-MM-DD ") + "00:00:00";
          data.startTime = dataTime;
        } else {
          data.startTime = "1970-01-01 00:00:00";
        }

        if (this_.search.endTime != null) {
          dataTime = this_.search.endTime.format("YYYY-MM-DD ") + "23:59:59";
          data.endTime = dataTime;
        } else {
          if (this_.search.startTime.toDate().getTime() > new Date().getTime()) {} else {
            data.endTime = moment().format("YYYY-MM-DD ") + "23:59:59";
          }
        }
      }

      this_.load_tracking_chart.showLoading({
        text: "数据正在加载。。。",
        color: "#4cbbff",
        textColor: "#4cbbff",
        maskColor: "rgba(61, 72, 85, 0.65)"
      });
      axios({
        method: "post",
        url: "/emind/history/data/load/tracking/data",
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error("登录超时，请重新登录", 2, function () {});
          return;
        }

        var remoteData = d.data.data;
        var newOp = this_.load_tracking_chart.getOption();
        newOp.series.splice(0, newOp.series.length);
        this_.averageIncome = 0;
        beautifyLoadTracking(remoteData, false);
        var min = new Date(this_.search.startTime).getTime();
        var max = new Date(this_.search.endTime).getTime();
        var nowDate = null;
        var markAreaData = [];

        if (this_.timePrice != null && this_.timePrice.details != null && this_.timePrice.details.length > 0) {
          for (var i = 0; min + 3600000 * 24 * i <= max; i++) {
            nowDate = new Date(min + 3600000 * 24 * i);
            nowDate = nowDate.Format("yyyy-MM-dd ");
            this_.timePrice.details.map(function (item) {
              markAreaData[markAreaData.length] = [{
                itemStyle: {
                  color: this_.colors[item.type - 1]
                },
                xAxis: new Date(nowDate + item.startTime.split(" ")[1])
              }, {
                xAxis: new Date(nowDate + item.endTime.split(" ")[1])
              }];
            });
          }
        }

        newOp.series.push({
          name: "关口表功率",
          type: "line",
          connectNulls: false,
          color: "#49AA19",
          areaStyle: {
            color: "rgba(73, 170, 25, 0.3)"
          },
          markArea: {
            data: markAreaData
          },
          symbol: "none",
          data: d.data.data.map(function (x) {
            if (x.gwm === "") {
              console.log(x);
              return {
                value: [new Date(x.recordTime), x.gwm]
              };
            }

            return {
              value: [new Date(x.recordTime), parseFloat(x.gwm.replace(",", ""))]
            };
          })
        });
        newOp.series.push({
          name: "并网点功率",
          type: "line",
          connectNulls: false,
          color: "#F26813",
          symbol: "none",
          areaStyle: {
            color: "rgba(242, 104, 19, 0.3)"
          },
          data: d.data.data.map(function (x) {
            if (x.gccm === "") {
              return {
                value: [new Date(x.recordTime), x.gccm]
              };
            }

            return {
              value: [new Date(x.recordTime), parseFloat(x.gccm.replace(",", "")) * -1]
            };
          })
        });
        newOp.series.push({
          name: "负载功率",
          type: "line",
          connectNulls: false,
          color: "#1890FF",
          areaStyle: {
            color: "rgba(24, 144, 255, 0.3)"
          },
          symbol: "none",
          data: d.data.data.map(function (x) {
            if (x.gccm === "") {
              return {
                value: [new Date(x.recordTime), x.gwm.replace(",", "")]
              };
            } else if (x.gwm === "") {
              return {
                value: [new Date(x.recordTime), parseFloat(x.gccm.replace(",", "")) * -1]
              };
            } else {
              return {
                value: [new Date(x.recordTime), (parseFloat(x.gwm.replace(",", "")) - parseFloat(x.gccm.replace(",", ""))).toFixed(2)]
              };
            }
          })
        });
        newOp.xAxis = {
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          splitLine: {
            show: false
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#7C868D"
            }
          },
          name: "",
          type: "time",
          min: this_.search.startTime.format("YYYY-MM-DD ") + "00:00:00",
          max: this_.search.startTime.format("YYYY-MM-DD ") + "23:59:59",
          boundaryGap: false,
          data: d.data.data.map(function (x) {
            return new Date(x.recordTime).Format("yyyy-MM-dd hh:mm:ss").replace(" ", "\n");
          }),
          axisTick: {
            alignWithLabel: true
          }
        };

        if (d.data.data.length < 1) {
          newOp.title = {
            textStyle: {
              fontFamily: "微软雅黑",
              color: "#E2E2E2"
            },
            text: "负荷跟踪曲线图",
            subtext: "\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据",
            x: "center"
          };
          newOp.dataZoom = null;
        } else {
          newOp.title = {
            textStyle: {
              fontFamily: "微软雅黑",
              color: "#E2E2E2"
            },
            text: "负荷跟踪曲线图",
            subtext: "",
            x: "center"
          };
          newOp.dataZoom = [{
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }, {
            type: "inside",
            realtime: true,
            start: 0,
            end: 100
          }];
        }

        this_.load_tracking_chart.hideLoading();
        this_.load_tracking_chart.setOption(newOp);
        this_.load_tracking_chart.resize();
      }).catch(function (error) {
        this_.load_tracking_chart.hideLoading();

        if (error.message != "") {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    init: function init(id) {
      var this_ = this;
      this_.load_tracking_option = {
        title: {
          textStyle: {
            fontFamily: "微软雅黑",
            color: "#E2E2E2"
          },
          text: "负荷跟踪曲线图",
          x: "center"
        },
        legend: {
          textStyle: {
            fontFamily: "微软雅黑",
            color: "#7C868D"
          },
          data: ["并网点功率", "关口表功率", "负载功率"],
          x: "left"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false
          },
          formatter: function formatter(params) {
            var relVal = "";

            if (params.length < 1) {
              return relVal;
            } else if (params.length == 1) {
              if (params[0].value == "" && typeof params[0].value[1] == "string") {
                return "没有数据";
              }

              var relVal = "日期：" + params[0].value[0].Format("yyyy-MM-dd hh:mm:ss");
              relVal += "<br/>" + params[0].seriesName + "：" + params[0].value[1] + "kW";
            } else if (params.length == 2) {
              if (params[0].value == "" && params[1].value == "" && typeof params[0].value[1] == "string") {
                return "没有数据";
              }

              var relVal = "日期：" + params[0].value[0].Format("yyyy-MM-dd hh:mm:ss");
              relVal += "<br/>" + params[0].seriesName + "：" + params[0].value[1] + "kW";
              relVal += "<br/>" + params[1].seriesName + "：" + params[1].value[1] + "kW";
            } else if (params.length == 3) {
              if (params[0].value[1] == "" && params[1].value[1] == "" && params[2].value[1] == "" && typeof params[0].value[1] == "string") {
                return "没有数据";
              }

              var relVal = "日期：" + params[0].value[0].Format("yyyy-MM-dd hh:mm:ss");
              relVal += "<br/>" + params[0].seriesName + "：" + params[0].value[1] + "kW";
              relVal += "<br/>" + params[1].seriesName + "：" + params[1].value[1] + "kW";
              relVal += "<br/>" + params[2].seriesName + "：" + params[2].value[1] + "kW";
            }

            return relVal;
          }
        },
        toolbox: {
          show: true,
          right: 20,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          }
        },
        axisPointer: {
          link: {
            xAxisIndex: "all"
          }
        },
        grid: {
          left: 50,
          right: 50,
          bottom: 80
        },
        xAxis: [{
          nameTextStyle: {
            color: "#7C868D",
            fontFamily: "微软雅黑"
          },
          splitLine: {
            show: false
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#7C868D"
            }
          },
          name: "时间",
          type: "time",
          min: this_.search.startTime.format("YYYY-MM-DD ") + "00:00:00",
          max: new Date(this_.search.endTime.toDate().getTime() + 86400000 - 1000 - this_.search.endTime.toDate().getTime() % 86400000 - 28800000).Format("yyyy-MM-dd hh:mm:ss"),
          boundaryGap: false,
          data: this_.data.map(function (x) {
            return new Date(x.recordTime).Format("yyyy-MM-dd hh:mm:ss").replace(" ", "\n");
          }),
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          nameTextStyle: {
            fontFamily: "微软雅黑",
            color: "#7C868D"
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#7C868D"
            }
          },
          splitLine: {
            lineStyle: {
              color: "#7C868D"
            }
          },
          name: "功率(kW)",
          type: "value"
        }],
        series: [{
          name: "关口表功率",
          type: "line",
          connectNulls: false,
          color: "#49AA19",
          data: this_.data.map(function (x) {
            return x.gwm;
          }),
          areaStyle: {
            color: "#49AA19"
          }
        }, {
          name: "并网点功率",
          type: "line",
          connectNulls: false,
          color: "#F26813",
          lineStyle: {
            width: 1
          },
          data: this_.data.map(function (x) {
            return x.gccm;
          }),
          areaStyle: {
            color: "#F26813"
          }
        }, {
          name: "负载功率",
          type: "line",
          connectNulls: false,
          color: "#1890FF",
          data: this_.data.map(function (x) {
            return x.gwm - x.gccm;
          }),
          areaStyle: {
            color: "#1890FF"
          }
        }]
      };

      if (this_.data.length < 1) {
        this_.load_tracking_option.title = {
          textStyle: {
            fontFamily: "微软雅黑"
          },
          text: "负荷跟踪曲线图",
          subtext: "\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r\n\r暂无数据",
          x: "center"
        };
        this_.load_tracking_option.dataZoom = null;
      } else {
        this_.load_tracking_option.title = {
          textStyle: {
            fontFamily: "微软雅黑"
          },
          text: "负荷跟踪曲线图",
          subtext: "",
          x: "center"
        };
        this_.load_tracking_option.dataZoom = [{
          show: true,
          realtime: true,
          start: 0,
          end: 100
        }, {
          type: "inside",
          realtime: true,
          start: 0,
          end: 100
        }];
      }

      this_.load_tracking_chart = this_.$echarts.init(document.getElementById(id));
      this_.load_tracking_chart.setOption(this_.load_tracking_option);
      this_.load_tracking_chart.resize();
      setTimeout(function () {
        window.onresize = function () {
          this_.load_tracking_chart.resize();
        };
      }, 500);
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.reflush();
    },
    exportExcel: function exportExcel(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var name = arguments.length > 2 ? arguments[2] : undefined;
      return new Promise(function (resolve, reject) {
        axios({
          method: "post",
          url: url,
          // 请求地址
          data: options,
          // 参数
          responseType: "blob" // 表明返回服务器返回的数据类型

        }).then(function (response) {
          resolve(response.data);
          var blob = new Blob([response.data], {
            type: "application/vnd.ms-excel"
          });
          var fileName = name;

          if (response.headers["content-disposition"].endsWith('.xls"')) {
            fileName = fileName + ".xls";
          } else if (response.headers["content-disposition"].endsWith('.zip"')) {
            fileName = fileName + ".zip";
          }

          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); //释放内存

            window.URL.revokeObjectURL(link.href);
          }
        }, function (err) {
          if (error.message != "") {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      });
    },
    exportFn: function exportFn() {
      // this.search.endTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      if (!this.search.startTime || !this.search.endTime) {
        this.$message.error("请先选择时间段");
        return;
      }

      if (this.search.startTime > this.search.endTime) {
        this.$message.error("结束时间早于起始时间，请重新设置查询时间！");
        return;
      }

      var data = {
        stationId: this.search.stationId,
        startTime: moment(this.search.startTime).format("YYYY-MM-DD ") + "00:00:00",
        endTime: moment(this.search.endTime).format("YYYY-MM-DD ") + "23:59:59"
      }; //   var dataTime = "";
      //   if (this.search.startTime != null) {
      //     dataTime =
      //       moment(this.search.startTime).format("YYYY-MM-DD ") + "00:00:00";
      //     data.startTime = dataTime;
      //   } else {
      //     data.startTime = "1970-01-01 00:00:00";
      //   }
      //   if (this.search.endTime != null) {
      //     dataTime =
      //       moment(this.search.endTime).format("YYYY-MM-DD ") + "23:59:59";
      //     data.endTime = dataTime;
      //   } else {
      //     if (this.search.startTime > Date.now()) {
      //     } else {
      //       data.endTime = moment().format("YYYY-MM-DD ") + "23:59:59";
      //     }
      //   }

      var now = new Date();
      var url = "/emind/history/data/load/tracking/export/" + now.getTime();
      this.exportExcel(url, data, "负荷跟踪-" + now.Format("yyyy-MM-dd_hh-mm-ss"));
    },
    disabledStartDate: function disabledStartDate(currentDate) {
      var minTime = Math.min(Date.now(), this.search.endTime.valueOf());
      return currentDate > minTime;
    },
    disabledEndDate: function disabledEndDate(currentDate) {
      return currentDate > Date.now();
    }
  }
};